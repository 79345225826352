const Data = {
    menuItems: [{
            name: "HOME",
            path: "home",
        },
        {
            name: "SERVICE",
            path: "service",
            offset: "-120",
        },
        {
            name: "WHY OMNIZE",
            path: "about",
            offset: "-100",
        },

        // {
        //     name: "Token",
        //     path: "token",
        //     offset: "-100",
        // },

        // {
        //     name: "Pages",
        //     path: "/blog-grid",
        //     subItems: [{
        //             name: "Blog Grid",
        //             path: "/blog-grid",
        //         },
        //         {
        //             name: "Blog List",
        //             path: "/blog-list",
        //         },
        //         {
        //             name: "Blog Full",
        //             path: "/blog-full",
        //         },
        //         {
        //             name: "Blog Single",
        //             path: "/blog-single",
        //         },
        //     ],
        // },
        // {
        //     name: "faq",
        //     path: "faq",
        //     offset: "-60",
        // },
        // {
        //     name: "CONTACT",
        //     path: "contact",
        //     offset: "-70",
        // },
    ],
}
export default Data